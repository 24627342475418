import React, { useState, useEffect } from 'react'
import style from './DashboardVolunteers.module.css'
import { FaPen, FaTimes, FaTrash } from 'react-icons/fa'

import axios from "axios";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { db, storage } from '../../firebaseConfig'
import { addDoc, collection, serverTimestamp, onSnapshot } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { v4 } from 'uuid'
import { useNavigate } from 'react-router-dom';

const MySwal = withReactContent(Swal);

const initialState = {
  volunteerName: "",
  volunteerPosition: "",
  volunteerImage: "",
  volunteerEmail: "",
  volunteerTwitter: "",
  volunteerInstagram: "",
  works: [
  
  ],
  educations: [
 
  ],
  skills: [
   
  ],
}

const DashboardVolunteers = () => {
  const [modal, setModal] = useState(false);
  const [volunteers, setVolunteers] = useState([])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [volunteerId, setVolunteerId] = useState("")
  const [state, setState] = useState(initialState);
  const [file, setFile] = useState(null)
  const [works, setWorks] = useState([])
  const [work, setWork] = useState("")
  const [skills, setSkills] = useState([])
  const [skill, setSkill] = useState('')
  const [education, setEducation] = useState("")
  const [educations, setEducations] = useState([])

  const navigate = useNavigate()


  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showProcessAlert, setShowProcessAlert] = useState(false);

  const handleShowDeleteModal = (volunteerId) => {
    setShowDeleteModal(!showDeleteModal)
    setVolunteerId(volunteerId)
  }

    // Adding Work History
    const addWorkHistory = (e) => {
      e.preventDefault()
      setWorks([...works, work]);
      setWork("")
    };

    // Adding Skill
    const addSkill = (e) => {
      e.preventDefault()
      setSkills([...skills, skill]);
      setSkill("")
    };

    // Adding Education
    const addEducation = (e) => {
      e.preventDefault()
      setEducations([...educations, education]);
      setEducation("")
    };

        //   Removing Work History
        const removeWorkHistory = (index) => {
          const removeWorkHistories = [...works];
          removeWorkHistories.splice(index, 1);
          setWorks(removeWorkHistories);
        };

        //   Removing Work History
        const removeSkill = (index) => {
          const removeSkill = [...skills];
          removeSkill.splice(index, 1);
          setSkills(removeSkill);
        };

        //   Removing Education
        const removeEducation = (index) => {
          const removeEducation = [...educations];
          removeEducation.splice(index, 1);
          setEducations(removeEducation);
        };

  const { volunteerName, volunteerPosition, volunteerImage, volunteerEmail, volunteerInstagram, volunteerTwitter } = state;

  const handleVolunteer = (e) => {
  setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleAddVolunteer = async (e) => {
    e.preventDefault();
    setShowProcessAlert(true);

    if (volunteerName && volunteerPosition && volunteerImage && volunteerEmail && volunteerInstagram && volunteerTwitter && works && skills && educations) {

      setState({...state, "educations": educations, "works": works, "skills": skills})
      console.log(state)

      try {
        await addDoc(collection(db, 'volunteers'), {
          ...state,
          timestamp: serverTimestamp(),
        });
        setShowProcessAlert(false);
        setShowSuccessAlert(true);

        navigate('/gycf/dashboard')
      } catch (error) {
        console.error("Error adding Volunteer:", error);
        setShowErrorAlert(true);
      }
    }
  };

  useEffect(() => {
    const uploadFile = () => {
      const storageRef = ref(storage, `volunteerImages/${file.name + v4()}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          switch (snapshot.state) {
            case 'paused':
              break;
            case 'running':
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
            alert('Upload complete can now submit event');
            setState((prev) => ({ ...prev, volunteerImage: downloadUrl }));
          });
        }
      );
    };

    file && uploadFile()
  }, [file]);


  useEffect(() => {

    const unsub = onSnapshot(
      collection(db, 'volunteers'),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setVolunteers(list);

      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [])



  const handleDeleteVolunteer = () => {
    axios.delete(`http://localhost:5000/api/deleteVolunteer/${volunteerId}`)
      .then(console.log("Success"))
      .catch((error) => console.error("Error fetching volunteer:", error));
  }

  const showModal = () => {
    setModal(!modal);
  };

  useEffect(() => {
    // Fetch properties from your server when the component mounts
    axios.get("http://localhost:5000/api/getVolunteers")
      .then((response) => setVolunteers(response.data))
      .catch((error) => console.error("Error fetching volunteer:", error));
  }, []);

  useEffect(() => {
    // Handle showing/hiding SweetAlerts here
    if (showProcessAlert) {
      MySwal.fire({
        title: <p>Adding Event</p>,
        didOpen: () => {
          MySwal.showLoading();
        },
      });
    }

    if (showSuccessAlert) {
      MySwal.fire({
        title: "Added",
        text: "Event Added Successfully",
        icon: "success",
        confirmButtonText: "Ok",
      });
    }

    if (showErrorAlert) {
      MySwal.fire({
        title: "Error",
        text: "Error Adding Event",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  }, [showProcessAlert, showSuccessAlert, showErrorAlert]);
  return (
    <div className={style.dashboardVolunteers}>
      <button className={style.showModalBtn} onClick={showModal}>
        +
      </button>
      {modal ? (
        <div className={style.modal}>
          <button className={style.closeModalBtn} onClick={showModal}>
            X
          </button>
          <h4 className={style.heading}>Add A Volunteer</h4>
          <form
            onSubmit={handleAddVolunteer}
            method="post"
            encType="multipart/form-data"
          >
            <div className={style.inputs}>
              <label htmlFor="">Image</label>
              <input
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
                type="file"
              />
            </div>
            <div className={style.inputs}>
              <label htmlFor="">Name</label>
              <input
                name='volunteerName'
                onChange={handleVolunteer}
                type="text"
              />
            </div>
            <div className={style.inputs}>
              <label htmlFor="">Email</label>
              <input
                name='volunteerEmail'
                onChange={handleVolunteer}
                type="email"
              />
            </div>
            <div className={style.inputs}>
              <label htmlFor="">Role</label>
              <input
                name='volunteerPosition'
                onChange={handleVolunteer}
                type="text"
              />
            </div>
            <div className={style.inputs}>
              <label htmlFor="">Instagram</label>
              <input
                name='volunteerInstagram'
                onChange={handleVolunteer}
                type="text"
              />
            </div>
            <div className={style.inputs}>
              <label htmlFor="">Twitter</label>
              <input
                name='volunteerTwitter'
                onChange={handleVolunteer}
                type="text"
              />
            </div>
            <div className={style.inputs}>
              <label htmlFor="">Work History</label>
              <div className={style.professionalStudies}>
              <input
                name='workHistory'
                type="text"
                value={work}
                onChange={(e) => setWork(e.target.value)}
              />
              <button onClick={addWorkHistory} >Add</button>
              </div>
              {
                works && <div className={style.workHistory}>
                 
                    {
                      works.map((work, i) => {
                        return (
                          <div key={i} className={style.work}>
                          <p> {work} </p>  <FaTimes onClick={() => {removeWorkHistory(i)}}/>
                        </div>
                        )
                      })
                    }
               
                </div>
              }
            </div>
            <div className={style.inputs}>
              <label htmlFor="">Skills</label>
              <div className={style.professionalStudies}>
              <input
                name='professionalStudies'
                type="text"
                value={skill}
                onChange={(e) => setSkill(e.target.value)}
              />
              <button onClick={addSkill}>Add</button>
              </div>
            </div>

            {
                skills && <div className={style.workHistory}>
                 
                    {
                      skills.map((skill, i) => {
                        return (
                          <div key={i} className={style.work}>
                          <p> {skill} </p>  <FaTimes onClick={() => {removeSkill(i)}}/>
                        </div>
                        )
                      })
                    }
               
                </div>
              }


            <div className={style.inputs}>
              <label htmlFor="">Education</label>
              <div className={style.professionalStudies}>
              <input
                name='professionalStudies'
                type="text"
                value={education}
                onChange={(e) => setEducation(e.target.value)}
              />
              <button onClick={addEducation}>Add</button>
              </div>
            </div>

            {
                educations && <div className={style.workHistory}>
                 
                    {
                      educations.map((education, i) => {
                        return (
                          <div key={i} className={style.work}>
                          <p> {education} </p>  <FaTimes onClick={() => {removeEducation(i)}}/>
                        </div>
                        )
                      })
                    }
               
                </div>
              }

            <button type='submit' >Add Volunteer</button>
          </form>
        </div>
      ) : (
        <div className={style.volunteerContents}>
          <h4 className={style.heading}>All Volunteer</h4>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Img</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {
                volunteers.map((volunteer) => {
                  return (
                    <>
                      <tr key={volunteer._id}>
                        <td> {volunteer.volunteerName} </td>
                        <td> {volunteer.volunteerEmail} </td>
                        <td> {volunteer.volunteerPosition} </td>
                        <td>
                          {" "}
                          <img
                            src={`http://localhost:5000${volunteer.volunteerImage}`}
                            alt="volunteerImg"
                          />{" "}
                        </td>
                        <td> <button> <FaPen /> </button> <button> <FaTrash onClick={() => { handleShowDeleteModal(volunteer._id) }} /> </button>  </td>
                      </tr>
                    </>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      )}

      {
        showDeleteModal && <div className={style.deleteModal}>
          <div className={style.deleteModalContent}>
            <h2>Are you sure you want to delete the Volunteer?</h2>
            <div className={style.deleteVolunteerBtns}>
              <button onClick={handleShowDeleteModal} >Cancel</button>
              <button onClick={handleDeleteVolunteer} className={style.deleteVolunteer} >Delete</button>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default DashboardVolunteers