import React, { useState, useEffect } from "react";
import style from "./DashboardGallery.module.css";
import axios from 'axios'

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { db, storage } from '../../firebaseConfig'
import { addDoc, collection, serverTimestamp, onSnapshot } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { v4 } from 'uuid'
import { useNavigate } from 'react-router-dom';

const MySwal = withReactContent(Swal);

const initialState = {
  gallery: "",
}

const DashboardGallery = () => {
  const [modal, setModal] = useState(false);
  const [galleryImage, setGalleryImage] = useState('')
  const [galleries, setGalleries] = useState([])
  const [galleryId, setGalleryId] = useState('')
  const [state, setState] = useState(initialState);
  const [files, setFiles] = useState(null)
  const [heading, setHeading] = useState("")

  const navigate = useNavigate()


      const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [showProcessAlert, setShowProcessAlert] = useState(false); 
    const [showDeleteModal, setshowDeleteModal] = useState(false)

    const { gallery } = state;

    const handleGallery = (e) => {
      setState({ ...state, [e.target.name]: e.target.value });
    };


    const addGallery = async (e) => {
      e.preventDefault();
    
      if (files && files.length > 0 && heading) {
        setShowProcessAlert(true);
    
        const galleryUrls = []; // Array to store image URLs
    
        for (let i = 0; i < files.length; i++) {
          const storageRef = ref(storage, `gallery/${files[i].name + v4()}`);
          const uploadTask = uploadBytesResumable(storageRef, files[i]);
    
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              // Handle upload progress if needed
            },
            (error) => {
              console.log(error);
            },
            async () => {
              try {
                const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
                galleryUrls.push(downloadUrl);
    
                if (galleryUrls.length === files.length) {
                  // All images are uploaded, now update Firestore
                  try {
                    await addDoc(collection(db, 'galleries'), {
                      heading: heading,
                      images: galleryUrls,
                      timestamp: serverTimestamp(),
                    });
    
                    setShowProcessAlert(false);
                    setShowSuccessAlert(true);
                    navigate('/gycf/dashboard');
                  } catch (error) {
                    console.error("Error adding Galleries:", error);
                    setShowErrorAlert(true);
                  }
                }
              } catch (error) {
                console.error("Error getting download URL:", error);
                setShowErrorAlert(true);
              }
            }
          );
        }
      } else {
        console.error('No files selected for upload');
        setShowErrorAlert(true);
      }
    };
    
    

    const handleShowDeleteModal = (galleryId) => {
     setshowDeleteModal(!showDeleteModal)
     setGalleryId(galleryId)
   }

  const showModal = () => {
    setModal(!modal);
  };

  const handleDeleteGallery = () => {
    axios.delete(`http://localhost:5000/api/deleteGallery/${galleryId}`)
      .then((response) => setGalleries(response.data))
      .catch((error) => console.error("Error fetching event:", error));
  }

  useEffect(() => {

    const unsub = onSnapshot(
      collection(db, 'galleries'),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setGalleries(list);
        console.log(galleries)
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // Handle showing/hiding SweetAlerts here
    if (showProcessAlert) {
      MySwal.fire({
        title: <p>Adding Gallery</p>,
        didOpen: () => {
          MySwal.showLoading();
        },
      });
    }

    if (showSuccessAlert) {
      MySwal.fire({
        title: "Added",
        text: "Event Added Successfully",
        icon: "success",
        confirmButtonText: "Ok",
      });
    }

    if (showErrorAlert) {
      MySwal.fire({
        title: "Error",
        text: "Error Adding Event",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  }, [showProcessAlert, showSuccessAlert, showErrorAlert]);
    return (
      <div className={style.dashboardGallerys}>
        <button className={style.showModalBtn} onClick={showModal}>
          +
        </button>
        {modal ? (
          <div className={style.modal}>
            <button className={style.closeModalBtn} onClick={showModal}>
              X
            </button>
            <h4 className={style.heading}>Add Images</h4>
            <form
              onSubmit={addGallery}
              encType="multipart/form-data"
            >
              <div className={style.inputs}>
                <label htmlFor="">Heading</label>
                <input type="text" onChange={(e) => {
                  setHeading(e.target.value)
                }} />
              </div>
              <div className={style.inputs}>
                <label htmlFor="">Image</label>
                <input multiple onChange={(e) => {
                  setFiles(e.target.files)
                }} type="file" />
              </div>
              <button type="submit">Add Images</button>
            </form>
          </div>
        ) : (
          <div className={style.eventContents}>
              <h4 className={style.heading}>All Images</h4>
              <div className={style.allImages}>
                {
                  galleries.map((gallery) => {
                    return (
                      <div key={gallery.id} className={style.image}>
                      <h4> {gallery.heading} </h4>
                      {
                        gallery.images.map((image) => {
                          return (
                            <img src={image} alt="galleryImg" />
                          )
                        })
                      }
                        <div className={style.deleteImage}>
                          <button onClick={() => {handleShowDeleteModal(gallery._id)}}  >Delete</button>
                        </div>
                      </div>
                    );
                  })
                }
                
              </div>
          </div>
        )}

{
        showDeleteModal && <div className={style.deleteModal}>
          <div className={style.deleteModalContent}>
            <h2>Are you sure you want to delete the Image?</h2>
            <div className={style.deleteGalleryBtns}>
              <button onClick={handleShowDeleteModal} >Cancel</button>
              <button onClick={handleDeleteGallery} className={style.deleteGallery} >Delete</button>
            </div>
          </div>
        </div>
      }
      </div>
    );
  };

  export default DashboardGallery;
