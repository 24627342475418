import React from 'react'
import OtherPagesHero from './../components/OtherPagesHero/OtherPagesHero'
import Footer from './../components/Footer/Footer'
import MainGallery from './../components/MainGallery/MainGallery'

const GalleryPage = () => {
  return (
    <>
    <OtherPagesHero heading="Gallery" />
    <MainGallery />
    <Footer />
    </>
  )
}

export default GalleryPage