import React, { useState, useEffect } from 'react'
import style from './MainGallery.module.css'
import { db } from '../../firebaseConfig'
import {  collection, onSnapshot } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'



const MainGallery = () => {
  const [modal, setModal] = useState(false)
  const [tempImgSrc, setTempImgSrc] = useState('')
  const [galleries, setGalleries] = useState([])
  const navigate = useNavigate()


  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc)
    setModal(true)
  }

  const closeModal = () => {
    setModal(false)
  }

  useEffect(() => {

    const unsub = onSnapshot(
      collection(db, 'galleries'),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setGalleries(list);
        console.log(galleries)
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [])
  return (
    <>
      <div className={style.mainGalleryWrapper}>
        {
          galleries.map((gallery) => {
            return (
              <div
                key={gallery.id}
                className={style.pic}
                onClick={() => {
                  navigate(`/gallery/${gallery.id}/${gallery.heading}`)
                }}
              >
                <h2> {gallery.heading} </h2>
                    
                        <img
                          src={gallery.images[0]}
                          alt="galleryImg"
                        />
                     
              </div>
            );
          })
        }

      </div>
    </>
  )
}

export default MainGallery