import education from './assets/images/education.png'
import climate from './assets/images/climate.png'
import donate from './assets/images/donate.png'
import health from './assets/images/health.png'
import unicef from './assets/images/unicef.png'
import together from './assets/images/together.png'

const focusProject = [
    {
        img: education,
        heading: "Educational Projects",
        brief: "NGOs often implement education programs in various themes throughout the developing world. These programs can be run by local and international NGOs",
        detail: "Non-Governmental Organizations (NGOs) play a crucial role in implementing education programs across the developing world. These programs, often tailored to address specific needs and challenges, aim to improve access to education, enhance the quality of learning, and empower communities through knowledge and skills. Both local and international NGOs are involved in these efforts, bringing unique perspectives and resources to the table."
    },
    {
        img: climate,
        heading: "Climate Change",
        brief: "These initiatives aim to mitigate the impacts of climate change or stop climate change altogether",
        detail: "Non-Governmental Organizations (NGOs) are at the forefront of implementing climate change initiatives across the developing world. These initiatives are designed to mitigate the impacts of climate change or, where possible, halt its progression. Both local and international NGOs are actively engaged in this critical work, employing a variety of approaches and strategies to address the multifaceted challenges posed by climate change."
    },
    {
        img: health,
        heading: "Health Projects",
        brief: "Some NGOs provide healthcare services, including family planning",
        detail: "Non-Governmental Organizations (NGOs) play a crucial role in providing healthcare services, including family planning, across the developing world. These services are essential in improving health outcomes, reducing maternal and child mortality, and empowering individuals to make informed choices about their reproductive health. Both local and international NGOs implement a range of healthcare initiatives, each tailored to address the specific needs of the communities they serve."
    },
    {
        img: donate,
        heading: "Charity Projects",
        brief: "These projects are often top-down efforts, with little participation or input from beneficiaries. They include NGOs directed at meeting the needs of disadvantaged people and groups",
        detail: "Non-Governmental Organizations (NGOs) often implement projects aimed at meeting the needs of disadvantaged people and groups. These projects, while well-intentioned, can sometimes be top-down efforts with limited participation or input from the beneficiaries they aim to serve. "
    },
    {
        img: unicef,
        heading: "Millinium Development Goals",
        brief: "These projects focus on achieving the Millennium Development Goals (MDGs). They often involve multiple models and partnerships among different stakeholders",
        detail: "NGO projects aimed at achieving the Millennium Development Goals (MDGs) typically involve a range of models and partnerships among various stakeholders. These projects are designed to address some of the most pressing global challenges, such as poverty, hunger, health, education, gender equality, and environmental sustainability. The collaborative nature of these initiatives often brings together governments, private sector entities, local communities, and international organizations to create comprehensive and multifaceted solutions. "
    },
    {
        img: together,
        heading: "Participatory Projects",
        brief: "These are self-help projects with local involvement in the form of money, tools, land, materials, or labor",
        detail: "Self-help projects are initiatives where local communities actively participate by contributing resources such as money, tools, land, materials, or labor. These projects emphasize local involvement and ownership, ensuring that the solutions are tailored to the community’s needs and sustainable in the long term."
    },
]

export default focusProject