import React from 'react'
import OtherPagesHero from '../components/OtherPagesHero/OtherPagesHero'
import Footer from '../components/Footer/Footer'
import MainContact from './../components/MainContact/MainContact'

const ContactPage = () => {
  return (
    <>
    <OtherPagesHero heading="Contact Us" />
    <MainContact />
    <Footer />
    </>
  )
}

export default ContactPage
