import React from 'react'
import style from './AboutSection.module.css'
import aboutImg from './../../assets/images/home-hero.jpg'
import { useNavigate } from 'react-router-dom'

const AboutSection = () => {
    const navigate = useNavigate()
  return (
    <div className={style.aboutSection}>
        <div className={style.aboutSectionWrapper}>
            <div className={style.aboutSectionImg}>
                <div className={style.ImgWrapper}>
                    <img src={aboutImg} alt="profile" />
                </div>
            </div>
            <div className={style.aboutSectionTxt}>
                <h2>About Us</h2>
                <p>The Network will consist of several community foundations. Each community foundation has in-depth understanding of the local Area, what the priority needs are and how best to address the issues. They are able to direct donors to fund courses they are not only passionate about but that will make the most difference.
               </p>
                <div className={style.aboutSectionBtn}>
                    <button onClick={() => { navigate("/about-us") }} >Learn More</button>
                    <button className={style.donate} >Donate</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutSection
