import React from 'react'
import OtherPagesHero from '../components/OtherPagesHero/OtherPagesHero'
import Footer from './../components/Footer/Footer'
import { useParams } from 'react-router-dom'
import FocusProjectComponent from '../components/FocusProjectComponent/FocusProjectComponent'

const FocusProjectPage = () => {
    const {focusId} = useParams()
  return (
    <>
    <OtherPagesHero heading={focusId} />
    <FocusProjectComponent />
    <Footer />
    </>
  )
}

export default FocusProjectPage
