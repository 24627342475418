import React, { useState, useEffect } from 'react'
import style from './FocusProjectComponent.module.css'
import { useParams } from 'react-router-dom'
import focusProject from '../../data'
import { db } from '../../firebaseConfig'
import { getDocs, query, collection, where } from 'firebase/firestore'
import { Swiper, SwiperSlide } from 'swiper/react';
import img from './../../assets/images/home-hero.jpg'
import { FaCalendarAlt, FaMapMarkerAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

const FocusProjectComponent = () => {
  const { focusId } = useParams()
  const projectType = focusId
  const [projects, setProjects] = useState([])

  useEffect(() => {
    focusId && getEventDetails();
    // eslint-disable-next-line 
  }, [focusId]);

  const project = focusProject.find(proj => proj.heading === focusId);

  if (!project) {
    return <div className={style.focusProjectComponent}>Project not found</div>;
  }

  const getEventDetails = async () => {
    const projectsRef = collection(db, "projects");
    const q = query(projectsRef, where("projectType", "==", projectType));
    const querySnapshot = await getDocs(q);

    const fetchedProjects = [];
    querySnapshot.forEach((doc) => {
      fetchedProjects.push({ id: doc.id, ...doc.data() });
    });

    setProjects(fetchedProjects);
  }

  return (
    <div className={style.focusProjectComponent}>
      <div className={style.focusProjectComponentWrapper}>
        <h1> {project.heading} </h1>
        <p> {project.brief} </p>

        <div className={style.events} key={project.id}>
          <Swiper
            slidesPerView={4}
            spaceBetween={40}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              480: {
                slidesPerView: 1.5,
                spaceBetween: 10,
              },
              430: {
                slidesPerView: 1.5,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
            className={style.mySwiper}
          >
            {
              projects && projects.map((project) => {
                return (
                  <SwiperSlide className={style.swiper} >
                    <div className={style.event}>
                      <div className={style.eventImg}>
                        <img src={project.projectImage} alt="event profile" />
                      </div>
                      <div className={style.eventTxt}>
                        <div className={style.location}>
                          <p> <FaCalendarAlt className={style.locationIcon} /> {project.projectLocation} </p>
                          <p> <FaMapMarkerAlt className={style.locationIcon} /> {project.projectDate}  </p>
                        </div>
                        <h4>{project.projectTitle.length > 25
                          ? `${project.projectTitle.substring(0, 25)}...`
                          : project.projectTitle}</h4>
                        {project.projectAbout.length > 100
                          ? `${project.projectAbout.substring(0, 100)}...`
                          : project.projectAbout}
                        <Link to={`/project/${project.id}`}>Read More</Link>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              })
            }
          </Swiper>

        </div>

      </div>
    </div>
  )
}

export default FocusProjectComponent
