import React from 'react'
import style from './AboutMain.module.css'
import aboutImg from './../../assets/images/home-hero.jpg'

const AboutMain = () => {
  return (
   <div className={style.aboutMain}>
        <div className={style.aboutMainWrapper}>
            <div className={style.aboutMainImg}>
                <div className={style.ImgWrapper}>
                    <img src={aboutImg} alt="profile" />
                </div>
            </div>
            <div className={style.aboutMainTxt}>
                <h2>About Us</h2>
                <p>The Network will consist of several community foundations. Each community foundation has in-depth understanding of the local Area, what the priority needs are and how best to address the issues. They are able to direct donors to fund courses they are not only passionate about but that will make the most difference.
               </p>
               <p>
               Community foundations are the only way for local people to give right to the heart of their communities wherever across Ghana. They address the widest possible range of issues and influence in the hardest to reach communities.
               </p>
               <p>
               The foundation will give Aid, Assist, Help, Facilitate among other things possible to local projects on range of issues, such as poverty alleviation, emergency crisis response and youth engagement.
               </p>
                <div className={style.aboutMainBtn}>
                    <button className={style.donate} >Donate</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutMain
