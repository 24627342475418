import React from 'react'
import Navbar from '../Navbar/Navbar'
import style from './HomeHero.module.css'
import { useNavigate } from 'react-router-dom'

const HomeHero = () => {
  const navigate = useNavigate()
  return (
    <div className={style.homeHero}>
        <Navbar />
        <div className={style.homeHeroWrapper}>
            <div className={style.welcome}>
              <p>welcome to</p>
              <h2>Global Youth Connect Foundation</h2>
              <p className={style.motto}>Join Us in empowering change</p>
              <div className={style.heroBtns}>
                <button onClick={() => {navigate("/about-us")}} >Learn More</button>
                <button className={style.donate} >Donate</button>
              </div>
            </div>
            <div className={style.achievements}>
              <div className={style.achievement}>
                <h3>$500+</h3>
                <p>Spent</p>
              </div>
              <div className={style.achievement}>
                <h3>1+</h3>
                <p>Countries Visited</p>
              </div>
              <div className={style.achievement}>
                <h3>5000+</h3>
                <p>People Impacted</p>
              </div>
              <div className={style.achievement}>
                <h3>20+</h3>
                <p>Volunteers</p>
              </div>
            </div>
        </div>
    </div>
  )
}

export default HomeHero
