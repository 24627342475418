import React from 'react'
import DashboardComponent from '../components/DashboardComponent/DashboardComponent'

const Dashboard = () => {
  return (
    <>
    <DashboardComponent />
    </>
  )
}

export default Dashboard