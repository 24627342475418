import React, { useState, useEffect } from 'react'
import style from './DashboardAdmin.module.css'
import { FaTrash } from 'react-icons/fa'
import axios from "axios";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const DashboardAdmins = () => {
  const [modal, setModal] = useState(false);
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [admins, setAdmins] = useState([])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [adminId, setAdminId] = useState("")

      const [showSuccessAlert, setShowSuccessAlert] = useState(false);
      const [showErrorAlert, setShowErrorAlert] = useState(false);
      const [showProcessAlert, setShowProcessAlert] = useState(false); 

      const handleShowDeleteModal = (volunteerId) => {
        setShowDeleteModal(!showDeleteModal)
        setAdminId(volunteerId)
      }

      const handleDeleteVolunteer = () => {
        axios.delete(`http://localhost:5000/api/deleteAdmin/${adminId}`)
          .then(console.log("Success"))
          .catch((error) => console.log("Error fetching admin:", error));
      }

    const showModal = () => {
      setModal(!modal);
  };

      const handleAddVolunteer = async (event) => {
        event.preventDefault();
        setShowProcessAlert(true);
        try {
          const formData = {
            email,
            password
          };

          const response = await axios.post(
            "http://localhost:5000/api/register",
            formData,
         
          );

          setShowSuccessAlert(true);
          setShowProcessAlert(false);
        } catch (error) {
          console.log("Error adding Admin:", error);
          setShowErrorAlert(true);
        }
      };
  
      useEffect(() => {
      // Fetch properties from your server when the component mounts
      axios.get("http://localhost:5000/api/getAdmins")
        .then((response) => setAdmins(response.data.admins))
        .catch((error) => console.log("Error fetching volunteer:", error));
    }, []);

      useEffect(() => {
        // Handle showing/hiding SweetAlerts here
        if (showProcessAlert) {
          MySwal.fire({
            title: <p>Adding Event</p>,
            didOpen: () => {
              MySwal.showLoading();
            },
          });
        }

        if (showSuccessAlert) {
          MySwal.fire({
            title: "Added",
            text: "Event Added Successfully",
            icon: "success",
            confirmButtonText: "Ok",
          });
        }

        if (showErrorAlert) {
          MySwal.fire({
            title: "Error",
            text: "Error Adding Event",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      }, [showProcessAlert, showSuccessAlert, showErrorAlert]);
  return (
    <div className={style.dashboardAdmins}>
      <button className={style.showModalBtn} onClick={showModal}>
        +
      </button>
      {modal ? (
        <div className={style.modal}>
          <button className={style.closeModalBtn} onClick={showModal}>
            X
          </button>
          <h4 className={style.heading}>Add A Volunteer</h4>
          <form
            onSubmit={handleAddVolunteer}
            method="post"
           
          >
            <div className={style.inputs}>
              <label htmlFor="">Email</label>
              <input
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                type="email"
              />
            </div>
            <div className={style.inputs}>
              <label htmlFor="">Password</label>
              <input
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                type="password"
              />
            </div>
            <button type='submit' >Add Admin</button>
          </form>
        </div>
      ) : (
        <div className={style.volunteerContents}>
          <h4 className={style.heading}>All Admin</h4>
          <table>
            <thead>
              <tr>
                <th>Email</th>
                <th>Password</th>
              </tr>
            </thead>
              <tbody>
                {
                  admins.map((admin) => {
                    return (
                 
                        <tr key={admin._id}>
                          <td> {admin.email} </td>
                          <td> <button> <FaTrash onClick={() => {handleShowDeleteModal(admin._id)}} /> </button>  </td>
                        </tr>
              
                    );
                  })
                }
            </tbody>
          </table>
        </div>
      )}

{
        showDeleteModal && <div className={style.deleteModal}>
          <div className={style.deleteModalContent}>
            <h2>Are you sure you want to delete the Volunteer?</h2>
            <div className={style.deleteVolunteerBtns}>
              <button onClick={handleShowDeleteModal} >Cancel</button>
              <button onClick={handleDeleteVolunteer} className={style.deleteVolunteer} >Delete</button>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default DashboardAdmins