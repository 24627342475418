import React, {useState, useEffect} from 'react'
import style from './MainEvent.module.css'
import { FaCalendarAlt, FaMapMarkerAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { db } from '../../firebaseConfig'
import { collection, onSnapshot } from 'firebase/firestore'


const MainEvent = () => {
  const [projects, setProjects] = useState([])

     // Get the number of Testimonies
     useEffect(() => {

      const unsub = onSnapshot(
        collection(db, 'projects'),
        (snapshot) => {
          let list = [];
          snapshot.docs.forEach((doc) => {
            list.push({ id: doc.id, ...doc.data() });
          });
          setProjects(list);
          // console.log(projects)
        },
        (error) => {
          console.log(error);
        }
      );
  
      return () => {
        unsub();
      };
      // eslint-disable-next-line
    }, [])
  return (
    <div className={style.mainEventsSection}>
    <div className={style.mainEventsSectionWrapper}>
        <div className={style.mainEventCards}>
          { projects.length === 0 ? <h2>No Project</h2> :
            projects.map((project) => {
              return (
                <div key={project.id} className={style.mainEventCard}>
                  <div className={style.mainEventCardImg}>
                    <img
                      src={project.projectImage}
                      alt="mainEvent"
                    />
                  </div>
                  <div className={style.mainEventCardTxt}>
                    <div className={style.mainEventTop}>
                      <p className={style.mainEventLocation}>
                        {" "}
                        <FaMapMarkerAlt
                          className={style.mainEventLocationIcon}
                        />{" "}
                        {project.projectLocation}
                      </p>
                      <p className={style.mainEventLocation}>
                        {" "}
                        <FaCalendarAlt
                          className={style.mainEventLocationIcon}
                        />
                        {project.projectDate}
                      </p>
                    </div>

                    <h4 className={style.mainEventHeading}>
                      {project.projectTitle.length > 25
                        ? `${project.projectTitle.substring(0, 25)}...`
                        : project.projectTitle}
                    </h4>

                    <p className={style.mainEventBrief}>
                      {project.projectAbout.length > 100
                        ? `${project.projectAbout.substring(0, 100)}...`
                        : project.projectAbout}
                    </p>

                    <Link to={`/project/${project.id}`}> Read More </Link>
                  </div>
                </div>
              );
            })
          }
        </div>
    </div>
  </div>
  )
}

export default MainEvent