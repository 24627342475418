import React, { useState, useEffect } from "react";
import { FaPen, FaTrash } from 'react-icons/fa'
import style from "./DashboardBlog.module.css";
import axios from 'axios'

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { db, storage } from '../../firebaseConfig'
import { addDoc, doc, getDoc, collection, serverTimestamp, onSnapshot } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { v4 } from 'uuid'
import { useNavigate } from 'react-router-dom';

const MySwal = withReactContent(Swal);

const initialState = {
  blogTitle: "",
  blogAbout: "",
  blogger: "",
  blogDate: "",
  blogImage: ""
}

const DashboardBlog = () => {
  const [modal, setModal] = useState(false);
  // const [blogTitle, setBlogTitle] = useState("")
  // const [blogger, setBlogger] = useState("")
  // const [blogAbout, setBlogAbout] = useState("")
  // const [blogImage, setBlogImage] = useState("")
  const [blogs, setBlogs] = useState([])
  const [blogId, setBlogId] = useState("")
  const [state, setState] = useState(initialState);
  const [file, setFile] = useState(null)

  const navigate = useNavigate()

   const [showSuccessAlert, setShowSuccessAlert] = useState(false);
   const [showErrorAlert, setShowErrorAlert] = useState(false);
   const [showProcessAlert, setShowProcessAlert] = useState(false);
  const [showDeleteModal, setshowDeleteModal] = useState(false)

   const handleShowDeleteModal = (blogId) => {
    setshowDeleteModal(!showDeleteModal)
    setBlogId(blogId)
  }

  const handleDeleteBlog = () => {
    axios.delete(`http://localhost:5000/api/deleteBlog/${blogId}`)
      .then((response) => setBlogs(response.data))
      .catch((error) => console.error("Error fetching event:", error));
  }

  const showModal = () => {
    setModal(!modal);
  };

  const { blogTitle, blogAbout, blogger, blogDate, blogImage } = state;

  const handleBlog = (e) => {
      setState({ ...state, [e.target.name]: e.target.value });
    };

  const handleAddBlog = async (e) => { 
    e.preventDefault();
    setShowProcessAlert(true);

    console.log(state)
    if (blogTitle && blogDate && blogAbout && blogger && blogImage) {

            try {
              await addDoc(collection(db, 'blogs'), {
                ...state,
                timestamp: serverTimestamp(),
              });
              setShowProcessAlert(false);
              setShowSuccessAlert(true);

              navigate('/dashboard')
            } catch (error) {
              console.error("Error adding Blog:", error);
              setShowErrorAlert(true);
            } 
          
    }

  
};

  useEffect(() => {
    const uploadFile = () => {
      const storageRef = ref(storage, `blogImages/${file.name + v4()}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          switch (snapshot.state) {
            case 'paused':
              break;
            case 'running':
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
            alert('Upload complete can now submit event');
            setState((prev) => ({ ...prev, blogImage: downloadUrl }));
          });
        }
      );
    };

    file && uploadFile()
}, [file]);
  
  useEffect(() => {

    const unsub = onSnapshot(
      collection(db, 'blogs'),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setBlogs(list);
        
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [])

      useEffect(() => {
    // Handle showing/hiding SweetAlerts here
    if (showProcessAlert) {
      MySwal.fire({
        title: <p>Adding Blog</p>,
        didOpen: () => {
          MySwal.showLoading()
        },
      });
    }

    if (showSuccessAlert) {
      MySwal.fire({
        title: 'Added',
          text: 'Blog Added Successfully',
          icon: 'success',
          confirmButtonText: 'Ok'
      });
    }

    if(showErrorAlert) {
      MySwal.fire({
        title: 'Error',
          text: 'Error Adding Blog',
          icon: 'error',
          confirmButtonText: 'Ok'
      });
    }
  }, [showProcessAlert, showSuccessAlert,showErrorAlert]);

  return (
    <div className={style.dashboardBlogs}>
      <button className={style.showModalBtn} onClick={showModal}>
        +
      </button>
      {modal ? (
        <div className={style.modal}>
          <button className={style.closeModalBtn} onClick={showModal}>
            X
          </button>
          <h4 className={style.heading}>Add A Blog</h4>
          <form
            onSubmit={handleAddBlog}
            method="post"
            encType="multipart/form-data"
          >
            <div className={style.inputs}>
              <label htmlFor="">Title</label>
              <input
              name="blogTitle"
                onChange={handleBlog}
                type="text"
              />
            </div>
            <div className={style.inputs}>
              <label htmlFor="">Blogger</label>
              <input
              name="blogger"
                onChange={handleBlog}
                type="text"
              />
            </div>
            <div className={style.inputs}>
              <label htmlFor="">Blog Date</label>
              <input
              name="blogDate"
                onChange={handleBlog}
                type="date"
              />
            </div>
            <div className={style.inputs}>
              <label htmlFor="">Content</label>
              <textarea
                onChange={handleBlog}
                name="blogAbout"
                id=""
                cols="30"
                rows="10"
              ></textarea>
            </div>
            <div className={style.inputs}>
              <label htmlFor="">Image</label>
              <input
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
                type="file"
              />
            </div>
            <button type="submit">Add Blog</button>
          </form>
        </div>
      ) : (
        <div className={style.eventContents}>
          <h4 className={style.heading}>All Blogs</h4>
          <table>
            <thead>
              <tr>
                <th>Title</th>
                <th>Blogger</th>
                <th>Date</th>
                <th>Content</th>
                <th>Img</th>
                <th>Edit</th>
              </tr>
            </thead>
              <tbody>
                {
                  blogs.map((blog) => {
                    return (
                      <>
                      <tr key={blog.id}>
                        <td>{blog.blogTitle}</td>
                        <td>{blog.blogger}</td>
                        <td>{blog.blogDate}</td>
                        <td>{blog.blogAbout}</td>
                        <td>
                          <img src={blog.blogImage} alt="blogImg" />
                        </td>
                        <td> <button> <FaPen /> </button> <button onClick={() => {handleShowDeleteModal(blog.id)}}> <FaTrash /> </button> </td>
                        </tr>
                        </>
                    );
                  })
                }
              
            </tbody>
          </table>
        </div>
      )}

{
        showDeleteModal && <div className={style.deleteModal}>
          <div className={style.deleteModalContent}>
            <h2>Are you sure you want to delete the blog?</h2>
            <div className={style.deleteBlogBtns}>
              <button onClick={handleShowDeleteModal} >Cancel</button>
              <button onClick={handleDeleteBlog} className={style.deleteEvent} >Delete</button>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default DashboardBlog;
