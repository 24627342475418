import React, {useState, useEffect} from 'react'
import style from './EventSection.module.css'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { FaCalendarAlt, FaMapMarkerAlt } from 'react-icons/fa'
import { db } from '../../firebaseConfig'
import { collection, onSnapshot } from 'firebase/firestore'

// Import Swiper styles
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

import { Link } from 'react-router-dom';

const EventSection = () => {
    const [projects, setProjects] = useState([])

    // Get the number of Testimonies
    useEffect(() => {

        const unsub = onSnapshot(
          collection(db, 'projects'),
          (snapshot) => {
            let list = [];
            snapshot.docs.forEach((doc) => {
              list.push({ id: doc.id, ...doc.data() });
            });
            setProjects(list);
            // console.log(projects)
          },
          (error) => {
            console.log(error);
          }
        );
    
        return () => {
          unsub();
        };
        // eslint-disable-next-line
      }, [])
    return (
        <div className={style.eventSection}>
            <div className={style.eventSectionWrapper}>
                <div className={style.eventSectionHeading}>
                    <h2>Projects</h2>
                    <p>Discover our latest initiative aimed at empowering local communities through sustainable practices. Join us as we work together to create a brighter, greener future.</p>
                    
                </div>
                <div className={style.events}>
                        <Swiper
                            loop={true}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                              }}
                              modules={[Autoplay]}
                              breakpoints={{
                                640: {
                                  slidesPerView: 2,
                                  spaceBetween: 10,
                                },
                                
                                430: {
                                  slidesPerView: 1.5,
                                  spaceBetween: 10,
                                },
                                768: {
                                  slidesPerView: 4,
                                  spaceBetween: 40,
                                },
                                1024: {
                                  slidesPerView: 4,
                                  spaceBetween: 30,
                                },
                                480: {
                                  slidesPerView: 1.5,
                                  spaceBetween: 10,
                                },
                              }}
                            className={style.mySwiper}
                        >
                            {
                    projects && projects.map((project) => {
                        return (
                                    <SwiperSlide className={style.swiper} >
                                        <div className={style.event}>
                                            <div className={style.eventImg}>
                                                <img src={project.projectImage} alt="event profile" />
                                            </div>
                                            <div className={style.eventTxt}>
                                                <div className={style.location}>
                                                    <p> <FaCalendarAlt className={style.locationIcon} /> {project.projectLocation} </p>
                                                    <p> <FaMapMarkerAlt className={style.locationIcon} /> {project.projectDate}  </p>
                                                </div>
                                                <h4>{project.projectTitle.length > 25
                        ? `${project.projectTitle.substring(0, 25)}...`
                        : project.projectTitle}</h4>
                                                {project.projectAbout.length > 100
                        ? `${project.projectAbout.substring(0, 100)}...`
                        : project.projectAbout}
                                                <Link to={`/project/${project.id}`}>Read More</Link>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                         )
                                        })
                                    }
                        </Swiper>
                    </div>
            </div>
        </div>
    )
}

export default EventSection
