import React, { useEffect, useState } from 'react'
import style from './VolunteersSection.module.css'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { db } from '../../firebaseConfig'
import { collection, onSnapshot } from 'firebase/firestore'

// Import Swiper styles
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

const VolunteersSection = () => {
  const [volunteers, setVolunteers] = useState([])
  useEffect(() => {

    const unsub = onSnapshot(
      collection(db, 'volunteers'),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setVolunteers(list);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [])
  return (
    <div className={style.volunteersSection}>
      <div className={style.volunteersSectionWrapper}>
        <div className={style.volunteersSectionHeading}>
          <h2>Volunteers</h2>
          <p>Welcome to our community! We're excited for you to meet our passionate and dedicated volunteers. Their commitment and hard work drive our mission forward. Come and get to know the amazing individuals who make our projects possible.</p>
        </div>
        <div className={style.volunteers}>
          <Swiper
            slidesPerView={5}
            spaceBetween={40}
            loop={true}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              640: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              
              768: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 50,
              },
              480: {
                slidesPerView: 2,
                spaceBetween: 5,
              },
              430: {
                slidesPerView: 2,
                spaceBetween: 5,
              },
              419: {
                slidesPerView: 1.5,
                spaceBetween: 5,
              },
              390: {
                slidesPerView: 1.5,
                spaceBetween: 5,
              },
              360: {
                slidesPerView: 1.5,
                spaceBetween: 5,
              },
              320: {
                slidesPerView: 1.5,
                spaceBetween: 5,
              },
            }}
            modules={[Autoplay]}
            className={style.mySwiper}
          >
            {
              volunteers.map((volunteer) => {
                return (
                  <SwiperSlide key={volunteer._id} className={style.swiper}>
                    <div className={style.volunteer}>
                      <div className={style.volunteerImg}>
                        <img
                          src={volunteer.volunteerImage}
                          alt="volunteer-profile"
                        />
                      </div>
                      <div className={style.volunteerTxt}>

                        <h3>{volunteer.volunteerName && volunteer.volunteerName.length > 15
                          ? `${volunteer.volunteerName.substring(0, 15)}...`
                          : volunteer.volunteerName}</h3>

                        <p>{volunteer.volunteerPosition}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })
            }
            <SwiperSlide className={style.swiper} >

            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default VolunteersSection
