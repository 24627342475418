import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import style from './EventSingle.module.css'
import { db } from '../../firebaseConfig'
import { getDoc, doc } from 'firebase/firestore'

const EventSingle = () => {
    const [project, setProject] = useState()
    const { projectId } = useParams();
    useEffect(() => {
        projectId && getEventDetails();
        // eslint-disable-next-line 
    }, [projectId]);

    const getEventDetails = async () => {
        const projectRef = doc(db, "projects", projectId);
        const docSnap = await getDoc(projectRef);

        if (docSnap.exists()) {
            setProject(docSnap.data())
            //   setEvent(docSnap.data())
        } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
        }
    }


    const splitTextIntoParagraphs = (text) => {
        const sentences = text.split('. '); // Split the text into an array of sentences
        const paragraphs = [];
        let paragraph = '';

        for (let i = 0; i < sentences.length; i++) {
            paragraph += sentences[i] + '. '; // Add a full stop to the end of each sentence

            // Check if 4 sentences are added to the paragraph
            if ((i + 1) % 4 === 0 || i === sentences.length - 1) {
                paragraphs.push(paragraph); // Push the paragraph to the paragraphs array
                paragraph = ''; // Reset the paragraph
            }
        }

        return paragraphs;
    };
    return (
        <div className={style.eventSingle}>
            <div className={style.eventSingleWrapper}>
                {
                    project && (
                        <div key={project._id} className={style.event}>
                            <div className={style.eventImage}>
                                <img src={project.projectImage} alt="event" />
                            </div>
                            <div className={style.eventContent}>
                                <h2 className={style.eventHeading}> {project.projectTitle} </h2>
                                {/* Display eventAbout with paragraphs */}
                                {splitTextIntoParagraphs(project.projectAbout).map((paragraph, index) => (
                                    <p key={index} className={style.eventAbout}>
                                        {paragraph}
                                    </p>
                                ))}
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default EventSingle