import React from 'react'
import style from './VolunteerBanner.module.css'
import { useNavigate } from 'react-router-dom'

const VolunteerBanner = () => {
  const navigate = useNavigate()
  return (
    <div className={style.volunteerBanner}>
        <div className={style.volunteerBannerWrapper}>
            <div className={style.volunteerBannerTxt}>
                <h4 className={style.volunteerBannerTxtHeading}>Ready To Join Us?</h4>
                <p className={style.volunteerBannerTxtPara}>Are you passionate about making a difference in the world? Join us in our mission to create lasting, positive change in our communities. At Global Youth Connect Foundation, we're not just an organization; we're a movement dedicated to transforming lives and building a better future for all.</p>
            </div>
            <button onClick={() => {navigate("/contact-us")}} className={style.volunteerBannerBtn}>Become a volunteer</button>
        </div>
    </div>
  )
}

export default VolunteerBanner