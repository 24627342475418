import React, {useState, useEffect} from "react";
import style from "./Partners.module.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { db, } from '../../firebaseConfig'
import { collection, onSnapshot } from 'firebase/firestore'


// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay } from "swiper/modules";

const Partners = () => {
  const [partners, setPartners] = useState([])

  useEffect(() => {

    const unsub = onSnapshot(
      collection(db, 'partners'),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setPartners(list);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [])
  return (
    <div className={style.partnersSection}>
      <div className={style.partnersSectionWrapper}>
        <div className={style.partnersHeading}>
          <h2>Partners & Sponsors</h2>
          <p>We are grateful for the support of our incredible sponsors and partners. Their generosity and collaboration empower us to achieve our mission. Explore the dedicated organizations and individuals who help make our work possible. 
          </p>
        </div>
        <div className={style.partnersCards}>
          <Swiper
            spaceBetween={0}
            // centeredSlides={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              480: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
              430: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
              768: {
                slidesPerView: 5,
                spaceBetween: 40,
              },
              1366: {
                slidesPerView: 6,
                spaceBetween: 50,
              },
            }}
            modules={[Autoplay]}
            className={style.mySwiper}
          >
            { partners.length === 0 ? <h2>No Partners yet</h2> :
              partners.map((partner) => {
                return (
                  <SwiperSlide key={partner._id} className={style.swiperSlide}>
                    <div className={style.partner}>
                      <div className={style.partnerImg}>
                        <img src={partner.partner} alt="partner-profile" />
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })
            }
            
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Partners;
