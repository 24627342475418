import React, {useState} from 'react'
import SideNav from '../SideNav/SideNav'
import style from './DashboardComponent.module.css'
import DashboardEvents from '../DashboardEvents/DashboardEvents'
import DashboardBlog from '../DashboardBlog/DashboardBlog'
import DashboardGallery from '../DashboardGallery/DashboardGallery'
import DashboardPartners from '../DashboardPartners/DashboardPartners'
import DashboardTestimonials from '../DashboardTestimonials/DashboardTestimonials'
import DashboardVolunteers from '../DashboardVolunteers/DashboardVolunteers'
import DashboardMain from '../DashboardMain/DashboardMain'
import DashboardEmail from '../DashboardEmail/DashboardEmail'
import DashboardAdmins from '../DashboardAdmin/DashboardAdmin'

const DashboardComponent = () => {
    const [activeBtn, setActiveBtn] = useState('0')

    const handleActiveBtn = (active) => {
        setActiveBtn(active);
  }
  
    const renderComponent = () => {
      switch (activeBtn) {
        case "0":
          return <DashboardMain />;
        case "1":
          return <DashboardEvents />  ;
        case "4":
          return <DashboardGallery /> ;
        case "5":
          return <DashboardTestimonials />;
        case "6":
          return <DashboardVolunteers />;
        case "7":
          return <DashboardPartners />;
        case "8":
          return  <DashboardEmail /> ;
        default:
          return <DashboardMain />;
      }
    };
  return (
    <div className={style.dashboardComponent}>
      <SideNav handleActiveBtn={handleActiveBtn} />
      <div className={style.dashboardContent}>{renderComponent()}</div>
    </div>
  );
}

export default DashboardComponent