import React from 'react'
import style from './Footer.module.css'
import logo from './../../assets/images/logo.png'
import {Link, useNavigate} from 'react-router-dom'
import { FaFacebookF, FaInstagramSquare, FaLinkedinIn, FaTwitter }  from 'react-icons/fa'

const Footer = () => {
    const navigate = useNavigate()
  return (
    <div className={style.footer}>
        <div className={style.footerWrapper}>
            <div className={style.footerAbout}>
                <div className={style.footerLogo}>
                    <img src={logo} alt="logo" />
                    <h4>GYCF</h4>
                </div>
                <p className={style.footerAboutPara}>We are dedicated to fostering positive change and empowering communities. Through our diverse projects and initiatives, we strive to create sustainable solutions and make a lasting impact. Join us on our mission to build a brighter future for everyone.</p>
            </div>
            <div className={style.footerLinks}>
                <h4 className={style.footerHeadings}>Quick Links</h4>
                <div className={style.footerLinksContent}>
                    <Link to="/projects" >Projects</Link>
                    <Link to="/gallery" >Gallery</Link>
                    <Link to="/about-us" >About Us</Link>
                    {/* <Link to="/" >FAQ</Link>
                    <Link to="/" >FAQ</Link> */}
                </div>
            </div>
            <div className={style.footersubscribe}>
            <h4 className={style.footerHeadings}>Subscribe</h4>
            <div className={style.footersubscribeInput}>
                <input type="text" placeholder='Email'  />
                <button>Subscribe</button>
            </div>

            <div className={style.footersubscribeSocials}>
                <h5>Follow Us On:</h5>
                <div className={style.footersubscribeSocialsContent}>
                    <a href="/"> <FaFacebookF /> </a>
                    <a href="/"> <FaTwitter /> </a>
                    <a href="/"> <FaInstagramSquare /> </a>
                    <a href="/"> <FaLinkedinIn /> </a>
                </div>
            </div>

            </div>
        </div>
        <div className={style.copy} onDoubleClick={() => {navigate("/gycf/dashboard")}} > <p> Copyright &copy; 2023 GYCF </p> </div>
    </div>
  )
}

export default Footer