import React from 'react'
import OtherPagesHero from './../components/OtherPagesHero/OtherPagesHero'
import AboutMain from '../components/AboutMain/AboutMain'
import Mission from './../components/Mission/Mission'
import VolunteersSection from './../components/VolunteersSection/VolunteersSection'
import VolunteersBanner from './../components/VolunteerBanner/VolunteerBanner'
import Partners from './../components/Partners/Partners'
import Footer from './../components/Footer/Footer'

const AboutPage = () => {
  return (
    <>
    <OtherPagesHero heading="About Us" />
    <Mission />
    <AboutMain />
    <VolunteersSection />
    <VolunteersBanner />
    <Partners />
    <Footer />
    </>
  )
}

export default AboutPage
