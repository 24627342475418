import React from 'react'
import style from './ContactCard.module.css'
import { useNavigate } from 'react-router-dom'

const ContactCard = () => {

  const navigate = useNavigate()
  return (
    <div className={style.contactCard}>
        <div className={style.contactCardWrapper}>
            <h4 className={style.contactCardHeading}>Have Any Questions Or Suggestions?</h4>
            <button className={style.contactCardBtn} onClick={() => {navigate('/contact-us')}} >Contact Us</button>
        </div>
    </div>
  )
}

export default ContactCard