import React, {useEffect, useState} from 'react'
import style from './DashboardMain.module.css'
import axios from 'axios'
import { db, storage } from '../../firebaseConfig'
import { addDoc, collection, serverTimestamp, onSnapshot } from 'firebase/firestore'


const DashboardMain = () => {
  const [partners, setPartners] = useState("")
  const [volunteers, setVolunteers] = useState("")
  const [projects, setProjects] = useState("")
  const [blogs, setBlogs] = useState("")
  const [galleries, setGalleries] = useState("")
  const [testimonies, setTestimonies] = useState("")
  const [agents, setAgents] = useState("")

  // get number of partners
  useEffect(() => {
    const unsub = onSnapshot(
      collection(db, 'partners'),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setPartners(list.length);
        console.log(partners)
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [])

  // Get the number of volunteers
  useEffect(() => {

    const unsub = onSnapshot(
      collection(db, 'volunteers'),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setVolunteers(list.length);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [])

  // Get the number of Events
  useEffect(() => {

    const unsub = onSnapshot(
      collection(db, 'projects'),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setProjects(list.length);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [])

  // Get the number of Blogs
  useEffect(() => {

    const unsub = onSnapshot(
      collection(db, 'blogs'),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setBlogs(list.length);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [])


  // Get the number of Galleries
  useEffect(() => {

    const unsub = onSnapshot(
      collection(db, 'galleries'),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setGalleries(list.length);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [])


  // Get the number of Testimonies
  useEffect(() => {

    const unsub = onSnapshot(
      collection(db, 'testimonies'),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setTestimonies(list.length);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [])

  
  return (
    <div className={style.dashboardMain}>
      <div className={style.dashboardMainWrapper}>
        <div className={style.dashboardMainCard}>
          <h2 className={style.number}>  { partners > 0 ? partners : 0} </h2>
          <p className={style.what}>Partners</p>
        </div>
        <div className={style.dashboardMainCard}>
          <h2 className={style.number}> {volunteers > 0 ? volunteers : 0 } </h2>
          <p className={style.what}>Volunteers</p>
        </div>
        <div className={style.dashboardMainCard}>
          <h2 className={style.number}> {projects > 0 ? projects : 0} </h2>
          <p className={style.what}>Projects</p>
        </div>
        <div className={style.dashboardMainCard}>
          <h2 className={style.number}> {blogs > 0 ? blogs : 0} </h2>
          <p className={style.what}>Blogs</p>
        </div>
        <div className={style.dashboardMainCard}>
          <h2 className={style.number}> {galleries > 0 ? galleries : 0} </h2>
          <p className={style.what}>Galleries</p>
        </div>
        <div className={style.dashboardMainCard}>
          <h2 className={style.number}> {testimonies > 0 ? testimonies : 0} </h2>
          <p className={style.what}>Testimonies</p>
        </div>
        <div className={style.dashboardMainCard}>
          <h2 className={style.number}>0</h2>
          <p className={style.what}>Resources</p>
        </div>
        <div className={style.dashboardMainCard}>
          <h2 className={style.number}>{agents > 0 ? agents : 0}</h2>
          <p className={style.what}>Admins</p>
        </div>
      </div>
    </div>
  )
}

export default DashboardMain