import React from 'react'
import OtherPagesComponent from './../components/OtherPagesHero/OtherPagesHero'
import Footer from './../components/Footer/Footer'
import MainEvent from '../components/MainEvent/MainEvent'

const EventPage = () => {
  return (
    <>
    <OtherPagesComponent heading="Projects" />
    <MainEvent />
    <Footer />
    </>
  )
}

export default EventPage
