import React from 'react'
import GallerySingleComponent from './../components/GallerySingleComponent/GallerySingleComponent'
import { useParams } from 'react-router-dom'
import OtherPagesHero from '../components/OtherPagesHero/OtherPagesHero'
import Footer from '../components/Footer/Footer'

const GallerySingle = () => {
  const {galleryHeading} = useParams()
  return (
    <>
    <OtherPagesHero heading={galleryHeading} />
    <GallerySingleComponent />
    <Footer />
    </>
  )
}

export default GallerySingle
