import React, {useState} from 'react'
import style from './Navbar.module.css'
import { Link } from 'react-router-dom'
import { FaBars, FaTimes } from 'react-icons/fa'

const Navbar = () => {
    const [res, setRes] = useState(false)

    const handleRes = () => {
        setRes(!res)
    }
    return (
        <div className={style.navbar}>
            <div className={style.navbarWrapper}>
                <div className={style.logo}>
                    <h1>GYCF</h1>
                </div>
                <div className={style.links}>
                    <div className={style.linksWrapper}>
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <Link to="/about-us">About Us</Link>
                            </li>
                            <li>
                                <Link to="/projects">Projects</Link>
                            </li>
                            <li>
                                <Link to="/gallery">Gallery</Link>
                            </li>
                            <li>
                                <Link to="/contact-us">Contact Us</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={style.navBtn}>
                    <button>Donate</button>
                </div>
                <div className={style.resBtn} onClick={handleRes}>
                    {
                        !res ?<button> <FaBars /> </button> : <button> <FaTimes /> </button>
                    }
                    
                </div>
            </div>
            <div className={ res ? style.resNav : style.noResNav}>
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                        <Link to="/projects">Projects</Link>
                    </li>
                    <li>
                        <Link to="/gallery">Gallery</Link>
                    </li>
                    <li>
                        <Link to="/contact-us">Contact Us</Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Navbar
